body {

  color: var(--primary-color);
  margin: 0;
  padding: 0;
  font-family: 'Nunito, sans-serif';
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  overscroll-behavior: none; /* Prevent overscroll altogether */
  height: 100%;
  margin: 0;
  padding: 0;
}

