html, body {
  height: 100%;
  margin: 0;
  height: 100%;
}

:focus { outline: 0; }

#root, .App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
}

fieldset {
  border: none;
}

@keyframes shake {
  0%, 100% { transform: translate(0, 0) }
  20% { transform: translate(-5px, 0) }
  40% { transform: translate(5px, 0) }
  60% { transform: translate(-5px, 0) }
  80% { transform: translate(5px, 0) }
}

.panic-shake {
  animation: shake 0.5s infinite;
}


