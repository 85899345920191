.backgroundContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../../assets/Registration_BG.png') no-repeat center center;
    background-size: cover;
}
  
.overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.95); /* Black with opacity */
    width: 33.33%;
    height: 33.33%;
    opacity: 1; /* Full opacity */
    transition: opacity 1s ease-in-out; /* Transition effect for opacity */
}
  
.overlay.revealed {
    opacity: 0; /* Fade to transparent */
}

.Container {
    transition: opacity 0.5s ease-in-out;
    opacity: 1; /* Initially visible */
  }
  
  .Container.hidden {
    opacity: 0; /* Fade out effect */
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .MuiPickersCalendarHeader-switchViewButton {
    width: 24px !important;
    height: 24px !important;
    padding: 0 !important;
    min-width: unset !important;
  }
  
  .MuiPickersCalendarHeader-switchViewButton:hover {
    width: 24px !important;
  }
  
  .MuiPickersCalendarHeader-switchViewButton:focus {
    width: 24px !important;
  }
  
  